
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'Owner',
  components: {},
  filters: {
    getStatusName: function (value: any) {
      // 签到状态 1正常 2缺上班签到 3缺下班签到 4上下班人数不一致
      if (value) {
        if (value === '0') {
          return '待确认'
        } else if (value === '1') {
          return '通过'
        } else {
          return '未申请'
        }
      }
    }
  }
})
export default class Person extends Vue {
  private loading = false
  private tableData = []
  private examineShow = false
  private InvitationCodeShow = false

  private code = ''
  private qrType = '1'

  private total = 0
  private page = 1
  private size = 10

  // private projectId = ''

  private formData = {
    projectId: '',
    status: '1'
  }

  created () {
    this.loadData()
    // this.projectId = this.$route.query.projectId as string
    // console.log('myid', this.projectId)
    // this.addOwner()
  }

  // 获取项目人员列表
  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.project.selectYhOwnerApplyByPage, {
      projectId: this.$route.query.projectId,
      page: this.page,
      size: this.size
    }).then((res: any) => {
      this.tableData = res.list || []
      this.total = res.total || 0
      this.loading = false
    })
  }

  // 获取项目人员列表
  addOwner () {
    this.loading = true
    this.$axios.post(this.$apis.project.insertYhOwnerApply, {
      projectId: this.$route.query.projectId,
      ownerName: '智慧养护管家'
    }).then((res: any) => {
      // this.tableData = res || []
      // this.total = res.total || 0
      // this.loading = false
    })
  }

  // 审核
  onExamine (applyId: any) {
    // this.examineShow = true
    this.$confirm('确认通过吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
      // center: true
    }).then(() => {
      this.$axios.post(this.$apis.project.updateYhOwnerApplyStatus, { applyId }).then(() => {
        // this.handleClose()
        this.$message.success('操作成功')
        this.loadData()
      })
    })
  }

  handleClose () {
    this.examineShow = false
  }

  // 审核
  onSave () {
    (this.$refs.formData as any).validate((valid: any) => {
      if (valid) {
        this.$axios.post(this.$apis.project.insertYhProjectByProjectId, {
          projectId: this.$route.query.projectId,
          status: this.formData.status
        }).then(() => {
          this.$message.success('操作成功')
          this.loadData()
        })
      }
    })
  }

  // 下载
  // onDownLoad2 (preview: boolean) {
  //   const src = 'imgUrl'
  //   const fileName ='imgName'
  //   const canvas = document.createElement('canvas')
  //   const img = document.createElement('img')
  //   // 解决跨域 Canvas 污染问题
  //   img.setAttribute('crossOrigin', 'Anonymous')
  //   // 将资源链接赋值过去，才能触发img.onload事件
  //   img.src = src
  //   img.onload = function (e) {
  //     canvas.width = img.width
  //     canvas.height = img.height
  //     const context = canvas.getContext('2d') as any
  //     // 绘制图片
  //     context.drawImage(img, 0, 0, img.width, img.height)
  //     canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height)
  //     // 将canvas转base64码，然后创建一个a连接自动下载图片
  //     canvas.toBlob((blob) => {
  //       const link = document.createElement('a')
  //       link.href = window.URL.createObjectURL(blob)
  //       link.download = fileName
  //       link.click()
  //     })
  //   }
  // }

  // 下载
  onDownLoad (preview: boolean) {
    this.InvitationCodeShow = true
    // this.$axios.downloadFile(this.$apis.project.selectOwnerApplyQRCode, {
    //   projectId: this.$route.query.projectId
    // }).then((res) => {
    //   if (preview) {
    //     // this.code = '' + res.filePrefix + res.fileUrl
    //   }
    // })
    this.$axios.get(this.$apis.project.selectOwnerApplyQRCode, {
      projectId: this.$route.query.projectId
    }).then((res) => {
      if (preview) {
        window.location.href = `${res.filePrefix}${res.fileUrl}?attname=${res.fileName}.png`
      } else {
        this.code = '' + res.filePrefix + res.fileUrl
      }
    })
  }

  // 删除
  onDelete (applyId: any) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
      // center: true
    }).then(() => {
      this.$axios.post(this.$apis.project.deleteYhOwnerApply, { applyId }).then(() => {
        // this.handleClose()
        this.$message.success('删除成功')
        this.loadData()
      })
    })
  }
}
